<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card flat class="xmin-h-[70vh] xpb-[60px]">
      <v-card-title class="xflex xflex-row xjustify-between xitems-center">
        <Filters
          :search="search"
          :value="selectedYearMonth"
          @change="navigate"
          type="monthly"
          tab="time"
          has-project
        >
        </Filters>
      </v-card-title>
      <v-card-text>
        <ProgressTable
          :loading="loading"
          :items="item ? item.summaries : []"
        ></ProgressTable>
        <!-- <pre>{{ item }}</pre> -->
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import ProgressTable from "../components/ProgressTable.vue";
export default {
  data() {
    return {
      loading: false,
      item: null,
      search: null,
      selectedYearMonth: moment().format("YYYY-MM"),
    };
  },
  watch: {
    "$route.params.year_month": {
      handler: function (val) {
        this.selectedYearMonth = val;
        val && this.fetch(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    selectedProject() {
      return this.$route.params.pid;
    },
    selectedUser() {
      return this.$route.params.user_id;
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-user-time-monthly",
          params: {
            year_month: val,
            user_id: this.selectedUser,
            pid: this.selectedProject,
          },
        })
        .catch(() => {});
    },
    fetch(year_month) {
      if (!this.selectedProject || !this.selectedUser || !year_month) return;
      this.loading = true;
      this.$axios
        .get(
          `api/project-user-report/${this.selectedProject}/${this.selectedUser}/monthly/${year_month}`
        )
        .then(({ data }) => {
          this.item = data;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { Filters, ProgressTable },
};
</script>

<style lang="scss" scoped></style>
